import './style.css';

import copiumText_png from './metadata/copium-text-inverse.png';

import meme1img from './metadata/memes/allincat.jpeg';
import meme2img from './metadata/memes/gamblingchallengecat.webp';
import meme3img from './metadata/memes/catbluff.jpeg';
import meme4img from './metadata/memes/snap4.jpg';
import meme5img from './metadata/memes/chadroulette.webp';
import meme6img from './metadata/memes/snap3.jpg';
import meme7img from './metadata/memes/maxresdefault111.jpg';
import meme8img from './metadata/memes/snap5.jpg';
import meme9img from './metadata/memes/photo_2024-07-13 3.24.27AM.jpeg';
import meme10img from './metadata/memes/snap2.jpg';
import meme11img from './metadata/memes/maxresdefault.jpg';
import meme12img from './metadata/memes/photo_2024-07-13 3.24.29AM.jpeg';
import meme13img from './metadata/memes/snap1.jpg';
import meme14img from './metadata/memes/divorcegamble.jpeg';


/////////////////////////
function copiumBg() {
  const imgWidth = window.innerWidth;
  const imgHeight = imgWidth * 0.5625;
  const requiredImages = Math.ceil(window.innerHeight/imgHeight);

  for (let i = 0; i < requiredImages; i++) {
    const img = document.createElement('img');
    img.classList.add('copiumBgImg');
    img.src = copiumText_png;
    document.body.appendChild(img);
  }
}

/////////////////////////
const header = document.querySelector('header');

const title = document.createElement('h1');
title.innerHTML = "MONEY LOVES GAMBLERS";
header.appendChild(title);

const subTitle = document.createElement('h2');
subTitle.innerHTML = "$MLG"
header.appendChild(subTitle);

/////////////////////////
const main = document.querySelector('main');

const scrollContainer = document.createElement('div');
scrollContainer.id = "scrollContainer";

const memes = [meme1img, meme2img, meme3img, meme4img, meme5img, meme6img, meme7img, meme8img, meme9img, meme10img, meme11img, meme12img, meme13img, meme14img];
memes.forEach((meme) => {
  const img = document.createElement('img');
  img.src = meme;
  scrollContainer.appendChild(img);
});

main.appendChild(scrollContainer);

/////////////////////////
const socials = document.getElementById('socials');

const telegramBtn = document.createElement('button');
telegramBtn.id = "telegramBtn";
telegramBtn.innerHTML = `Telegram`;
socials.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.id = "twitterBtn";
twitterBtn.innerHTML = `Twitter`;
socials.appendChild(twitterBtn);

const chartBtn = document.createElement('button');
chartBtn.id = "chartBtn";
chartBtn.innerHTML = `Chart`;
socials.appendChild(chartBtn);

const buyBtn = document.createElement('button');
buyBtn.id = "buyBtn";
buyBtn.innerHTML = `BUY`;
socials.appendChild(buyBtn);

/////////////////////////
const footer = document.querySelector('footer');

const contract = document.createElement('span');
contract.innerHTML = "DrG6PfCHWFTvva4LqoWBmTt331QmtCiLPtSXn4gbpump";
footer.appendChild(contract);

const copyContract = document.createElement('button');
copyContract.innerHTML = "COPY";
footer.appendChild(copyContract);

/////////////////////////
document.addEventListener('DOMContentLoaded', function() {
  copiumBg();

  // Hyper Links
  telegramBtn.addEventListener('click', () => window.open("https://t.me/MLG_Sol", "_self"));
  twitterBtn.addEventListener('click', () => window.open("https://x.com/MLGSol_", "_self"));
  chartBtn.addEventListener('click', () => window.open("https://dexscreener.com/solana/", "_blank"));
  buyBtn.addEventListener('click', () => window.open("https://pump.fun/DrG6PfCHWFTvva4LqoWBmTt331QmtCiLPtSXn4gbpump", "_blank"));

  // Copy Contract Logic
  const textToCopy = contract.innerHTML;
  copyContract.addEventListener('click', async() => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch(err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });
});